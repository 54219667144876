import styled from 'styled-components/macro';

export const InputArea = styled.div`
  display: inline-block;
  background: linear-gradient(180deg, #24272B 0%, #272C30 100%);
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3), inset 0px 2px 3px rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  width: 350px;
  height: 48px;
  position: relative;
  ;
  width: 90%;
  >input
{
    display: inline-block;
    text-align: left;
    height: 40px;
    padding-top: 10px;
    width: 90%;
    border: 0px;
    font-size: 19px;
    text-align: center;
    background: transparent;
    outline: none;
    color: white;
    &::placeholder
{
      color: rgba(gray, 0.2);
}}
`;
