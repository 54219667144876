
import refreshPoolInfo from './GlobalActions/refreshPoolInfo'
import refreshSwapInfo from './GlobalActions/refreshSwapInfo'
import { updateQuery } from './updateQuery'

async function refreshView(state) {
    if (state.root.page == "Sell") {
        return await refreshSwapInfo(state, true)
    }

    if (state.root.page == "Buy") {
        return await refreshPoolInfo(state, true)
    }

    return null
}

async function refreshAll(isRecursion) {
    //console.log("refreshAll", isRecursion)
    if (isRecursion != true && refreshAll.$inprocess) 
        return
    if(refreshQueue.length == 0) {

        delete refreshAll.$inprocess
        return
    }
    refreshAll.$inprocess = true
    
    const state = refreshQueue.shift()
    state.data.loading = true
    try {
        state.data.error = null
        await refreshView(state)
        updateQuery(state)
        state.data.loading = false
        console.log("done")
    }
    catch(err) {
        state.root.page = "Sell"
        state.data.error = `Network Issue or it is not supported (${err})`
        state.data.loading = false
    }
    
    refreshAll(true, alert)

}

const refreshQueue = []

function addToRefreshQueue(state) {
    refreshQueue.length = 0;
    refreshQueue.unshift(state)

    refreshAll(false)
}



export default addToRefreshQueue;