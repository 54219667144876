import React from 'react'
import styled from 'styled-components/macro'
import Web3ReactManager from '../components/Web3ReactManager'
import { useSwapzPoolContract, useTokenContract } from '../hooks/useContract'
import SwapMain from './SwapMain/MainForm.tsx'
import { useAlert } from "react-alert";
//import { getContract } from 'utils'
import Web3 from 'web3'
import getMakerAddress from './getMakerAddress'


const Marginer = styled.div`
  margin-top: 5rem;
`

const GetSwapzContract = (swapAddress) => {

  const address =
    Web3.utils.isAddress(swapAddress) ? swapAddress : null

  return useSwapzPoolContract(address, true)  

}




const Body = (state: any) => {
  
  const data = state.data;

  const maker = data.coins[data.maker.coin];

  const tokenAddress = maker ? maker.tokenAddress : null

  const swapAddress = getMakerAddress(state);
  
  const tokenContract = useTokenContract( Web3.utils.isAddress(tokenAddress) ? tokenAddress : null, true)
  
  const swapContract = GetSwapzContract(swapAddress)

  const alert = useAlert();
  
  //if (swapAddress == null) 
  //  return null;

  return (
    <div style={{ background: "linear-gradient(180deg, #353A40 0%, #16171B 100%)" }}>
        <Web3ReactManager>
              { SwapMain(state, tokenContract, swapContract, alert)}
        </Web3ReactManager>
      <Marginer />
      
    </div>
)
}


export default Body
