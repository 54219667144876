import React from 'react'
import styled from 'styled-components/macro'
import Navigate from '../Navigate'


const SwitchTab = styled.div`
  z-index: 100;
  position: absolute;
  padding: 0 20px;
  z-index: 40;
  cursor: pointer;
  top: 0px;
  height: 55px;
  line-height: 55px;
  &.Sell
  {
      left: 23px;
  }
    &.Buy
  {
      right: 23px;
  }
`
const mapping = {
  "Buy" : "Take",
  "Sell" : "Make"
}

export default function Component(state: any, page: string) {
    const switchPage = ()=> {

      //if (page == "Buy")
      //  return

      Navigate(state, page)

      

    }
    return (
        <SwitchTab key={'switchtab' + page} className={page} onClick={switchPage}>{mapping[page]}</SwitchTab>
    )
}