import { MaxUint256 } from '@ethersproject/constants';
import getMakerAddress from '../getMakerAddress'
export const allowOrder = (state: any, tokenContract: any, alert: any) => () => {
  state.data.loadingButton = true;


  tokenContract = tokenContract.attach(state.data.coins[state.data.maker.coin].tokenAddress)
  if (tokenContract == null) {
    alert.info("Token Contract is null");
    state.data.loadingButton = null;
    return;
  }

  

  const makerAddress = getMakerAddress(state);

  //https://www.youtube.com/watch?v=Sib9_yW_rLY

  //
  //
  tokenContract.approve(makerAddress, MaxUint256, {
    gasLimit: 180000,
    value: 0
  }).then((response: any) => {
    if (response.hash) {
      response
        .wait(1)
        .then((x: any) => {
          state.data.loadingButton = null;
          state.data.maker.allowance = MaxUint256;
        })
        .catch((x: any) => {
          state.data.loadingButton = null;
          alert.info("" + x);
        });
    }
  })
    .catch((error: Error) => {
      state.data.loadingButton = null;

      if (error && error.message) {
        alert.info("" + error.message);
      }
      else {
        alert.info("" + error);
      }


    });
};
