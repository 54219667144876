import ERC20_ABI from '../../abis/erc20.json';
import getMakerAddress from '../getMakerAddress'
const getTokenInfo = async (state, coin, web3, account) => {

  const contract = new web3.eth.Contract(ERC20_ABI, coin.tokenAddress);

  const makerAddress = getMakerAddress(state);

  const allowanceBN = await contract.methods.allowance(account, makerAddress).call();
  const balance = await contract.methods.balanceOf(account).call();
  console.log("allowanceBN", allowanceBN.toString());
  return { allowanceBN, balance };

};
const getNativeInfo = async (store, coin, web3, account) => {

  const balance = await web3.eth.getBalance(account);

  const allowanceBN = 1;

  return { allowanceBN, balance };

};
export const getBalanceInfo = async (store, coin, web3, account) => {
  if (coin.tokenAddress == "native" || coin.tokenAddress == "0x0000000000000000000000000000000000000000") {
    return getNativeInfo(store, coin, web3, account);
  }
  else {
    //alert("get token info " + account + ' '+ coin.tokenAddress)
    return getTokenInfo(store, coin, web3, account);
  }
};
