import { Currency } from '@uniswap/sdk-core'
import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
//import EthereumLogo from '../../assets/images/ethereum-logo.png'
//import useHttpLocations from '../../hooks/useHttpLocations'
//import { WrappedTokenInfo } from '../../state/lists/wrappedTokenInfo'
import Logo from '../Logo'

export const getTokenLogoURL = (blockchain: string, address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${blockchain}/assets/${address}/logo.png`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`

export default function CurrencyLogo({
  currency,
  size = '23px',
  style,
  ...rest
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  
  if (currency == null)
    return null;

  if (currency.logo) {
    return <img width={size} style={style} src={currency.logo} alt="logo" {...rest}  />
  }

  const logo = getTokenLogoURL(currency.trustWalletFolder, currency.trustWalletAddress);

  
  return <StyledEthereumLogo src={logo} size={size} style={{ borderRadius: "25px", ...style}} {...rest} />
  

}
