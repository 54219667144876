import Web3 from 'web3'
import { NETWORK_URLS } from '../../connectors'
//import ERC20_ABI from '../../abis/erc20.json'
import POOL_ABI from '../../abis/pool.json'
//import POOL_ABI from '../../abis/pool.json'
//import { transaction } from 'mobx'
//import resetSwapInfo from '../resetSwapinfo'
//import { makeHumanAmounts } from './makeHumanAmounts'
//import { getBalanceInfo } from './getTokenInfo'
import getMakerAddress from 'pages/getMakerAddress';
//import { BigNumber } from "ethers";

const getRange = (length, count)=> {
  const current = length == 0 ? [] : [length];
  return (count < 1 || length < 0) ? [] : current.concat(getRange(length - 1 , count - 1));  
}

const makeRequest = (state, makerPoolContract)=> (index)=> {
  console.log("index", index)
  return makerPoolContract.methods.getActiveOrder(index).call().then((x)=> ({...x, index}));
}

const getItems = async (state, makerPoolContract, length, pageSize)=> {
  console.log("getItems")
  if (length == 0)
    return []
  const range = getRange(length - 1, pageSize)
  //console.log("range", range)
  return await Promise.all(range.map(makeRequest(state, makerPoolContract)));
}

const getPage = async (state, page)=> {
  const makerWeb3 = new Web3(NETWORK_URLS[state.data.maker.chainId])
  const makerAddress = getMakerAddress(state);
  const makerPoolContract = new makerWeb3.eth.Contract(POOL_ABI, makerAddress)
  const length = await makerPoolContract.methods.getActiveOrderLength().call();
  const pageSize = state.data.pageSize;
  
  state.data.totalPages = Math.ceil(length / pageSize);
  //console.log("length", length, state.data.totalPages);
  
  return await getItems(state, makerPoolContract, length - (page * pageSize), pageSize);
}

const onlyPublicAndMine = (state: any)=> (order: any)=> {
  return true  
  //return order.buyer == "0x0000000000000000000000000000000000000000" || order.buyer == state.account || order.owner == state.account;
}

async function refreshPoolInfo(state: any, reset: bool) {
  if (reset) {
    state.data.currentPage = 0;
    state.data.totalPages = 0;
  }
  const orders = await getPage(state, state.data.currentPage);
  //console.log("orders", orders)
  state.data.orders = orders.filter(onlyPublicAndMine(state)).map((x)=> ({ ...x, fill: null, balance: 0, allowance: null }));
  //console.log("state.data.orders", state.data.orders.length)
}

export default refreshPoolInfo
