import styled from 'styled-components/macro';

export const SendInput = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  input
{
    background: transparent;
    height: 48px;
    border: 0px;
    text-align: right;
    font-size: 25px;
    outline: none;
    color: white;
}
`;
