import { get } from 'superagent';

export default (state: any, field: string) => async (e: any) => {
    const coinIndex = state.data[field].coin;
    const field2 = field == "maker" ? "taker" : "maker";
    const coinIndex2 = state.data[field2].coin;

    state.data[field].amount = e.target.value;
    state.data[field].placeholder = 0;

    const coin1 = state.data.coins[coinIndex];
    const coin2 = state.data.coins[coinIndex2];

    const usdRateResponse = await get(`https://min-api.cryptocompare.com/data/pricemulti?fsyms=${coin1.text},${coin2.text}&tsyms=USD`);
    const usdRates = JSON.parse(usdRateResponse.text);

    const coin1UsdRate = usdRates[coin1.text].USD;
    const coin2UsdRate = usdRates[coin2.text].USD;
    if (!(+coin1UsdRate > 0 && +coin2UsdRate > 0))
        return;
    const amount = (+state.data[field].amount * +coin1UsdRate) / +coin2UsdRate;
    const amount2 = Math.floor(amount * 10000) / 10000;
    const traderInterest = amount2 / 100;
    state.data[field2].placeholder = amount2 - traderInterest;
};