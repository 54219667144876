// Generated by LiveScript 1.6.0
(function(){
    var ethereum, arbitrum, xdai, ropsten, kovan, bsc, bsctest, polygontest, polygon, huobi, huobitest, velas, velastest, fantom, fantom_testnet, avalanche, avalanche_fuji_testnet, okex, okex_tesnet, optimism, ethereumEnv, polygonEnv, binanceEnv, huobiEnv, velasEnv, fantomEnv, avalancheEnv, okexEnv, optimismEvn, arbitrumEnv, xdai_env, supportedNetworks;
    ethereum = {
      type: 'evm',
      name: 'ethereum',
      coin: "ETH",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      blockTimeSec: 15,
      id: 1,
      blockExplorer: "https://etherscan.com/",
      gasTracker: {
        url: "https://ethgasstation.info/api/ethgasAPI.json?",
        type: 'json',
        path: {
          fast: 'fast',
          safeLow: 'safeLow',
          average: 'average'
        },
        acceptedRangeGwai: {
          min: 800,
          max: 400
        }
      },
      rpc: ['https://ropsten.infura.io/v3/098f1300675f4ff5a0b9ddf476c6eeca']
    };
    arbitrum = {
      type: 'evm',
      name: 'Arbitrum',
      coin: "AETH",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/10273.png",
      blockTimeSec: 10,
      id: 42161,
      blockExplorer: "https://arbiscan.io",
      gasTracker: {
        url: "https://ethgasstation.info/api/ethgasAPI.json?",
        type: 'json',
        path: {
          fast: 'fast',
          safeLow: 'safeLow',
          average: 'average'
        },
        acceptedRangeGwai: {
          min: 800,
          max: 400
        }
      },
      rpc: ['https://arb1.arbitrum.io/rpc']
    };
    xdai = {
      type: 'evm',
      name: 'XDAI',
      coin: "XDAI",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/8635.png",
      blockTimeSec: 10,
      id: 100,
      blockExplorer: "https://blockscout.com/xdai/mainnet",
      gasTracker: {
        url: "https://ethgasstation.info/api/ethgasAPI.json?",
        type: 'json',
        path: {
          fast: 'fast',
          safeLow: 'safeLow',
          average: 'average'
        },
        acceptedRangeGwai: {
          min: 800,
          max: 400
        }
      },
      rpc: ['https://rpc.xdaichain.com/']
    };
    ropsten = {
      type: 'evm',
      name: 'ropsten',
      coin: "ETH",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      blockTimeSec: 10,
      id: 3,
      blockExplorer: "https://ropsten.etherscan.com/",
      gasTracker: {
        url: "https://ethgasstation.info/api/ethgasAPI.json?",
        type: 'json',
        path: {
          fast: 'fast',
          safeLow: 'safeLow',
          average: 'average'
        },
        acceptedRangeGwai: {
          min: 800,
          max: 400
        }
      },
      rpc: ['https://ropsten.infura.io/v3/098f1300675f4ff5a0b9ddf476c6eeca']
    };
    kovan = {
      type: 'evm',
      name: 'kovan',
      coin: "ETH",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
      blockTimeSec: 4,
      id: 42,
      blockExplorer: "https://kovan.etherscan.com/",
      rpc: ['https://kovan.infura.io/v3/098f1300675f4ff5a0b9ddf476c6eeca'],
      gasTracker: {
        url: "https://ethgasstation.info/api/ethgasAPI.json?",
        type: 'json',
        path: {
          fast: 'fast',
          safeLow: 'safeLow',
          average: 'average'
        },
        acceptedRangeGwai: {
          min: 800,
          max: 400
        }
      }
    };
    bsc = {
      type: 'evm',
      name: "BinanceSmartChain",
      coin: "BSC",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
      blockTimeSec: 4,
      blockExplorer: "https://www.bscscan.com/",
      id: 56,
      rpc: ['https://bsc-dataseed.binance.org/', 'https://bsc-dataseed1.defibit.io/', 'https://bsc-dataseed1.ninicoin.io/', 'https://bsc-dataseed2.defibit.io/', 'https://bsc-dataseed3.defibit.io/', 'https://bsc-dataseed4.defibit.io/', 'https://bsc-dataseed2.ninicoin.io/', 'https://bsc-dataseed3.ninicoin.io/', 'https://bsc-dataseed4.ninicoin.io/', 'https://bsc-dataseed1.binance.org/', 'https://bsc-dataseed2.binance.org/', 'https://bsc-dataseed3.binance.org/', 'https://bsc-dataseed4.binance.org/'],
      gasTracker: {
        acceptedRangeGwai: {
          min: 50,
          max: 500
        }
      }
    };
    bsctest = {
      type: 'evm',
      name: "Binance Smart Testnet",
      coin: "BSC",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
      blockTimeSec: 4,
      id: 97,
      blockExplorer: "https://testnet.bscscan.com/",
      wss: ['wss://bsc.getblock.io/testnet/?api_key=0f04d6c3-1ba6-497e-b3d9-14748dbc2657'],
      rpc: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
      gasTracker: {
        acceptedRangeGwai: {
          min: 50,
          max: 500
        }
      }
    };
    polygontest = {
      type: 'evm',
      name: 'PolygonTestnet',
      coin: "MATIC",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
      blockTimeSec: 2,
      id: 80001,
      blockExplorer: "https://mumbai.polygonscan.com/",
      rpc: ['https://rpc-mumbai.matic.today', 'https://matic-mumbai.chainstacklabs.com', 'https://rpc-mumbai.maticvigil.com', 'https://matic-testnet-archive-rpc.bwarelabs.com'],
      gasTracker: {
        url: "https://gasstation-mainnet.matic.network",
        type: 'json',
        path: {
          fast: 'fast',
          safeLow: 'safeLow',
          average: 'standard'
        },
        acceptedRangeGwai: {
          min: 10,
          max: 100
        }
      }
    };
    polygon = {
      type: 'evm',
      name: 'polygon',
      coin: "MATIC",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
      blockTimeSec: 2,
      id: 137,
      blockExplorer: "https://polygonscan.com/",
      rpc: [ 'https://matic-mainnet.chainstacklabs.com', 'https://rpc-mainnet.matic.network', 'https://rpc-mainnet.maticvigil.com', 'https://rpc-mainnet.matic.quiknode.pro', 'https://matic-mainnet-full-rpc.bwarelabs.com', 'https://matic-mainnet-archive-rpc.bwarelabs.com'],
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    huobi = {
      type: 'evm',
      name: 'huobi',
      coin: "HT",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/2502.png",
      blockTimeSec: 2,
      id: 128,
      rpc: ['https://http-mainnet-node.huobichain.com/'],
      blockExplorer: 'https://hecoinfo.com',
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    huobitest = {
      type: 'evm',
      name: "HuobiTestnet",
      coin: "HT",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/2502.png",
      blockTimeSec: 2,
      id: 256,
      rpc: ['https://http-testnet.hecochain.com'],
      blockExplorer: 'https://testnet.hecoinfo.com',
      faucet: 'https://scan-testnet.hecochain.com/faucet',
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    velas = {
      type: 'evm',
      name: 'velas',
      coin: "VLX",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/4747.png",
      blockTimeSec: 2,
      blockExplorer: "https://evmexplorer.velas.com",
      id: 106,
      rpc: ['https://evmexplorer.velas.com/rpc'],
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    velastest = {
      type: 'evm',
      name: 'VelasTestnet',
      coin: "VLX",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/4747.png",
      blockTimeSec: 2,
      blockExplorer: "https://explorer.testnet.velas.com",
      id: 111,
      rpc: ['https://testnet.velas.com/rpc/'],
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    fantom = {
      type: 'evm',
      name: 'fantom',
      coin: "FTM",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
      blockTimeSec: 2,
      id: 250,
      blockExplorer: 'https://ftmscan.com/',
      rpc: ['https://rpc.ftm.tools/'],
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    fantom_testnet = {
      type: 'evm',
      name: "FantomTestnet",
      coin: "FTM",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
      blockTimeSec: 2,
      id: 16386,
      blockExplorer: 'https://ftmscan.com/',
      rpc: ['https://rpc.testnet.fantom.network/'],
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    avalanche = {
      type: 'evm',
      name: 'Avalanche',
      coin: "AVAX",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
      blockTimeSec: 2,
      id: 43114,
      blockExplorer: 'https://cchain.explorer.avax.network/',
      rpc: ['https://api.avax.network/ext/bc/C/rpc'],
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    avalanche_fuji_testnet = {
      type: 'evm',
      name: 'AvalancheFujiTestnet',
      coin: "AVAX",
      icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
      blockTimeSec: 2,
      id: 43113,
      blockExplorer: 'https://cchain.explorer.avax-test.network',
      rpc: ['https://api.avax-test.network/ext/bc/C/rpc'],
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    okex = {
      type: 'evm',
      name: 'OKExChain',
      coin: "OKT",
      icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/294.png",
      blockTimeSec: 2,
      id: 66,
      blockExplorer: 'https://www.oklink.com/okexchain/',
      rpc: ['https://exchainrpc.okex.org/'],
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    okex_tesnet = {
      type: 'evm',
      name: "OKExTestnet",
      coin: "OKT",
      icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/294.png",
      blockTimeSec: 2,
      id: 65,
      blockExplorer: 'https://www.oklink.com/okexchain-test',
      rpc: ['https://exchaintestrpc.okex.org'],
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    optimism = {
      type: 'evm',
      name: 'Optimistic',
      coin: "eth",
      icon: 'https://s2.coinmarketcap.com/static/img/exchanges/64x64/1419.png',
      blockTimeSec: 2,
      id: 10,
      blockExplorer: 'https://optimistic.etherscan.io',
      rpc: ['https://mainnet.optimism.io'],
      gasTracker: {
        acceptedRange: {
          min: 10,
          max: 100
        }
      }
    };
    ethereumEnv = [ethereum, ropsten, kovan];
    polygonEnv = [polygon, polygontest];
    binanceEnv = [bsc, bsctest];
    huobiEnv = [huobi, huobitest];
    velasEnv = [velas, velastest];
    fantomEnv = [fantom, fantom_testnet];
    avalancheEnv = [avalanche, avalanche_fuji_testnet];
    okexEnv = [okex, okex_tesnet];
    optimismEvn = [optimism];
    arbitrumEnv = [arbitrum];
    xdai_env = [xdai];
    supportedNetworks = velasEnv.concat(ethereumEnv, polygonEnv, binanceEnv, huobiEnv, fantomEnv, avalancheEnv, okexEnv, optimismEvn, arbitrumEnv, xdai_env);
    module.exports = supportedNetworks;
  }).call(this);