import React from 'react'
import Header from '../components/Header'
import ErrorBoundary from '../components/ErrorBoundary'
import styled from 'styled-components/macro'
import { observer } from 'mobx-react'
//import { reaction } from 'mobx'
import Swap from './Swap'
import { useActiveWeb3React } from '../hooks/web3'
import Navigate from './Navigate'
import { useEffect } from 'react'
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
//import { tryAddToken } from './SellForm/addToken'
import { state } from './state'
//import { useSwapzPoolContract } from '../hooks/useContract'


const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 2;
`

/*
function getPage() {
  const params = location.hash.replace('#/', '').replace("#", "").split("/")
  if(params[0].length == 0)
    return "Buy"
  return toTitleCase(params[0]); 
}
*/

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};


 
async function firstNavigate(state: any) {

  const data = state.data

  const makerChainId = state.data.maker.chainId;

  const maker = data.coins.find((x: any)=> x.chainId == makerChainId) || data.coins.find((x: any)=> x.chainId == state.chainId)

  if (maker == null) {
    return Navigate(state, state.root.page)
  }

  const group = 
    data.coins.filter((x: any)=> x.chainId == maker.chainId).map((x:any)=> x.group ).sort().reverse()[0]

  const takerChainId = state.data.taker.chainId;
  
  const taker = data.coins.find((x: any)=> x.chainId == takerChainId) || data.coins
  .find((x:any)=> x.chainId != maker.chainId && x.group == group )

  

  if (maker != null) {
    data.maker.chainId = maker.chainId
  }
  else {
    data.maker.chainId = state.chainId
  }
  
  if (taker != null) {
    data.taker.chainId = taker.chainId
  }

  const chainId = maker.chainId


  if (taker && maker && state.chainId && chainId != state.chainId) {

    state.data.loading = false;
    state.root.requestChainId = chainId
    return 
  }

  return Navigate(state, state.root.page)
  //state.data.loading = true
  /*
  switchNetwork(coin.chainId, (err: any)=> {
    //state.data.loading = false
    if (err) {
      console.log("first navigate", 9)
      Navigate(state, state.root.page)
    }
    console.log("first navigate", 10)
    Navigate(state, state.root.page)
  })
  */


}


const Body = observer(({ state }: any) => {
  const activeWeb3 = useActiveWeb3React()
  const { chainId, account } = activeWeb3;
  const newState = { ...state, chainId, account }
  if (newState.data.maker.chainId == null) {
    //console.log("set chainId", chainId, activeWeb3);
    newState.data.maker.chainId = chainId
  }
  useEffect(()=> {
    const accountHash = chainId && account ? chainId + account : 'na'
    
    if (state.root.accountHash == null || state.root.accountHash != accountHash) {
      //location.hash = "#/"
      newState.root.accountHash = accountHash
      //tryAddToken(newState)
      firstNavigate(newState)
      
      
    }
  })
  return (
    <ErrorBoundary>
        <AppWrapper>
          <HeaderWrapper>
            {Header(newState)}
          </HeaderWrapper>
          { Swap(newState) }
        </AppWrapper>
      </ErrorBoundary>
  )
});

export default function App() {
  
  return (
    <Provider template={AlertTemplate} {...options}>
      <Body state={state} />
    </Provider>
  )
}
