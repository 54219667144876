import { DEFAULT_LOCALE, SupportedLocale } from 'constants/locales'
import { useMemo } from 'react'



/**
 * Returns the supported locale read from the user agent (navigator)
 */
export function navigatorLocale(): SupportedLocale | undefined {
  return undefined
}

export function useSetLocaleFromUrl() {
  //const parsed = useParsedQueryString()
  //const [userLocale, setUserLocale] = useUserLocaleManager()

  /*
  useEffect(() => {
    const urlLocale = typeof parsed.lng === 'string' ? parseLocale(parsed.lng) : undefined
    if (urlLocale && urlLocale !== userLocale) {
      setUserLocale(urlLocale)
    }
  }, [parsed.lng, setUserLocale, userLocale])
  */
}

/**
 * Returns the currently active locale, from a combination of user agent, query string, and user settings stored in redux
 */
export function useActiveLocale(): SupportedLocale {
  const userLocale = DEFAULT_LOCALE

  return useMemo(() => {
    return DEFAULT_LOCALE
  }, [userLocale])
}
