import React from 'react'
import { SendInput } from './components/SendInputComponent';
import { InputArea } from './InputArea';
import SendDropDownCoins from './SendDropDownCoins.tsx'
import styled from 'styled-components/macro'
import changeAmount from './changeAmount';


export default function Component(state: any, field: string) {
    
    

    return (
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <div style={{ position: "relative", width: "400px", display: "inline-block" }}>
                <InputArea>
                {SendDropDownCoins(state, field)}
                    <SendInput>
                        <input placeholder="0.00" style={{width: '200px'}} value={state.data[field].amount} onChange={changeAmount(state, field)} />
                    </SendInput>
                </InputArea>
                { getMarketPrice(state, field) }
                
                </div>
        </div>
    )
}

const MarketPriceButton = styled.div`
    display: inline-block;
    padding: 2px 10px;
    border: 1px solid #455a77;
    border-radius: 6px;
    background: #252a2d;
    cursor: pointer;
    color: white;
`

const Panel = styled.div`
    vertical-align: top;
    display: inline-block;
    width: 50%;
`

const Label = styled.div`
    font-size: 12px;
    color: gray;
    vertical-align: top;
`

function getMarketPrice(state: any, field: string): string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined {
    
    if (state.data[field].coin == -1) {
        return null;
    }

    const coin = state.data.coins[state.data[field].coin];

    if (coin == null) {
        return <div>{field} #{state.data[field].coin} token is not found</div>
    }
    
    const useMarketPrice = ()=> {
        state.data[field].amount = state.data[field].placeholder;
    }
    return (<div style={{textAlign: "left", padding: "0 23px"}}>
                <div style={{width: "100%"}}>
                    <Panel><Label>Balance</Label> <div style={{display: "inline-block"}}>{state.data[field].balance} {coin.text}</div> </Panel>
                    { state.data[field].placeholder > 0 ? (<Panel style={{textAlign: "right"}}><Label>Recommended Amount</Label> <MarketPriceButton onClick={useMarketPrice}>{state.data[field].placeholder}</MarketPriceButton></Panel>): null}
                </div>
            </div>);
}
