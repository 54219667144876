//import { useSwapzPoolContract, useTokenContract } from '../../hooks/useContract'
//import { Trans } from '@lingui/macro'
import React from 'react'
import styled, { keyframes }  from 'styled-components'
//import { ButtonPrimary } from '../../components/Button'
//import { AutoColumn } from '../../components/Column'
//import CurrencyInputPanel from '../../components/CurrencyInputPanel'
//import { MaxUint256 } from '@ethersproject/constants'
//import { Wrapper } from '../../components/swap/styleds'
//import AppBody from '../AppBody'
//import { thousandsSeparators } from 'currency-thousand-separator'
//import { observer } from 'mobx-react'
import refreshPoolInfo from '../GlobalActions/refreshPoolInfo.js'
import refreshVestingInfo from '../GlobalActions/refreshVestingInfo.js'
//import { BigNumber } from 'ethers'
//import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
//import SwapInfo from '../PoolInfo'

//import LogoDark from '../../assets/svg/logo_white.svg'
//import { normalizeAmount } from '../SwapForm/normalizeAmount'
//import chooseNetwork from './chooseNetwork'
//import { NETWORK_LABELS } from '../../constants/chains'
//import renderAmount from '../SwapForm/renderAmount';
//import getFee from '../SwapForm/getFee'
//import Web3 from 'web3'
//import { Loading } from './Loading'
//import renderAllowButton from './renderAllowButton'
//import User from '../../assets/svg/user.svg'
//import CloudOff from '../../assets/svg/cloud-off.svg'
//import { Message, MessageIcon, MessageText } from './Message'
//import ConnectWallet from '../SwapMain/ConnectWallet.tsx'
//import Lottie from 'react-lottie-player'
//import globearth from './globe-earth.json'
//import { colorify } from 'lottie-colorify';
//import CurrencyLogo from '../../components/CurrencyLogo';
//import Slider  from '../../components/Slider';
//import { action } from 'mobx';
//import { BigNumber } from "ethers";
import Orders from "./Orders";
import Vestings from "./Vestings";
import classNames from "classnames";




const FilterPanel = styled.div`
  text-align: left;
  padding: 30px 18px;
  padding-bottom: 5px;
`

const FilterButton = styled.div`
  display: inline-block;
  width: 100px;
  height: 40px;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  border-radius: 10px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  color: gray;
  background: #24272B;
  &.active {
    color: white;
    cursor: default;
  }
`


const Container = styled.div`
  display: inline-block;
  text-align: left;
  width: 400px;
`

const Tabs = { Vestings, Orders }
const Refresh = { Vestings: refreshVestingInfo, Orders: refreshPoolInfo }
const Checkes = { Vestings: (state: any)=> { return state.data.vestings.length > 0 }, Orders: (state:any)=> { return state.data.orders.length > 0 } }

const openTab = (state: any, name: string)=> async ()=> {
    state.data.tab = name;
    await Refresh[name](state, false)
}


const PoolForm = (state: any, tokenContract: any, swapContract: any, alert: any) => {
  const textAlign = Checkes[state.data.tab](state) ? "left" : "center";

  return (
    <div>
    <Container>
      <FilterPanel style={{ textAlign: textAlign }}>
          <FilterButton onClick={openTab(state, 'Orders')} className={classNames({ "active" : state.data.tab == "Orders" })}>Orders</FilterButton>
          <FilterButton onClick={openTab(state, 'Vestings')} className={classNames({ "active" : state.data.tab == "Vestings" })}>Vestings</FilterButton>
      </FilterPanel>
      { Tabs[state.data.tab](state, tokenContract, swapContract, alert) }
    </Container>
    </div>
    )
}

export default PoolForm;