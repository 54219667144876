import { observable } from 'mobx';
import Coins from 'Coins.json';
import { SupportedChainId } from '../constants/chains';

/*
function getMakerAddress() {
  const params = location.hash.replace('#/', '').replace("#", "").split("/")
  if(params.length == 1)
    return null
  return params[1]
}
*/

// 

// 0xF1c76aF4e77e8d998ccE302D772f29ab21E92333
//0xf8FFCE003Ffc10F2F6A39251dd89b255a7CbCdab
//0xa214d5EEc385cC95516278bd7b9c609475a7C8d2

export const state = observable({
  root: {
    page: "Sell",
    makerAddress: { 
      "111": "0xaC6Ee2F8F174a1395752C4832CF83Bf64291Bde4",
      "106": "0xa214d5EEc385cC95516278bd7b9c609475a7C8d2"
    },
    accountHash: null,
    requestChainId: null
  },
  data: {
    tab: "Orders",
    error: null,
    successText: "",
    orders: [],
    vestings: [],
    pageSize: 2,
    currentPage: 0,
    totalPages: 0,
    limitBuyer: false,
    enableVesting: false,
    process: {
      stage: 0
    },
    loading: true,
    loadingButton: null,
    expandAllInfo: false,
    expandNetworkList: null,
    expandOptionsList: null,
    account: null,
    coins: Coins,
    maker: {
      coin: null,
      search: "",
      p2pAddress: "",
      vesting: 365,
      vestingInterval: 5,
      referral: "",
      options: [],
      amount: 0,
      placeholder: 0,
      balance: 0,
      allowance: 0,
      fee: 0
    },
    taker: {
      coin: null,
      recipient: "",
      search: "",
      chainId: SupportedChainId.MAINNET,
      chainIdPool: null,
      amount: 0,
      placeholder: 0,
      options: [],
      balance: 0,
      balancePool: 0,
      balanceEth: 0,
      volumeLast24: 0,
      totalTrades: 0,
      max: 0,
      confirmationPercent: 0,
      fee: {
        LP: 0,
        Crosschain: 0.01
      }
    }
  }
});
