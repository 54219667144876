import React from 'react'

import ConnectWallet from '../../assets/images/connect-your-wallet.png'


export default function Component(state: any) {
    console.log("Connect Wallet");
    const connectWallet = ()=> {
      document.getElementById("connect-wallet").click()
    }
    return (
      <div>
        <img src={ConnectWallet} style={{width: "250px", opacity: 1, cursor: "pointer"}} onClick={connectWallet} />
      </div>
      )

}