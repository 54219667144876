import React from 'react'
import styled from 'styled-components/macro'
import CurrencyLogo from '../../components/CurrencyLogo'
//import { updateQuery } from '../updateQuery'
//import { action } from 'mobx'
import refreshSwapInfo from 'pages/GlobalActions/refreshSwapInfo'

const SendDropDownCoins = styled.div`
  position: absolute;
  top: 8px;
  left: 9px;
  line-height: 48px;
  >*
{
    vertical-align: top;
    display: inline-block;
}
  >img
{
    height: 32px;
    width: 32px;
}
`
const SendDropDownCoinsText = styled.div`
  margin-top: -8px;
  margin-left: 5px;
`
const SendDropDownCoinsArrow = styled.div`
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAfCAYAAADqUJ2JAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAAHwAAAABiOXbkAAABcklEQVRIDa3VzW2DMBQH8GdCRXNLD0EiJ4/QEcgG7QgdoRu0G3SDdoRuQEfoBuGGxCU5cii4728BIsUGG2opfBi/H7zwbARxO59Ou3q7fSeldhuip7skydHv0wIMrqMoZeSBD9OaKDsXhUS/T9NQE4Y5CXFpA+USTHR3LcvyPmiaDOm1fTmneXRNs4cQvAa7gtZgI2gppv9sBA9bHMffTRAc/74ApD4cNzw2QhjQY0R5GyDxMmyYMbU2UO8Krqkbri0+kbqDywRPixvp83YzC2GcC+YEuWDO0BzmBU1h3pANs75+BNjabVVdlFLdJCfMT+8nwtr1E0WZEKIvTkX06gXZkH2SvDhDWOywTnG6sksZTwIE506QEVHqeX84vHXoLGRCGl7X4yT56BDsJyFXZBLyQayQL2KEliAjaClyBa1BemiE8CrIc+mRi+0Lg1xaiEFcsSnvJP+4IMxLqb42sdGzf1NVnzxGf7ZN6/FE/P9f+gWJ5ORfQgouPAAAAABJRU5ErkJggg==);
  width: 8px;
  height: 13px;
  background-size: cover;
  transform: rotate(90deg);
  position: absolute;
  width: 6px;
  height: 10px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
`

const MakerOptions = styled.div`
  position: absolute;
  z-index: 9999;
  display: inline-block;
  background: linear-gradient(180deg,#24272B 0%,#272C30 100%);
  width: 200px;
  min-height: 110px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
  left: -20px;
  top: -17px;
  box-shadow: 0px 1px 0px rgb(255 255 255 / 30%), inset 0px 2px 3px rgb(0 0 0 / 50%);
  border-radius: 25px 0px 25px 25px;
`

const MakerOption = styled.div`
  text-align: left;
  padding-left: 10px;
`

const makerOption = (state: any, field: string, option: any)=> {
  const selectOption = ()=> {
    //alert(state.data.coins.indexOf(option))
    state.data[field].coin = state.data.coins.indexOf(option)
    state.data[field].amount = 0
    state.data[field].options = []
    refreshSwapInfo(state, false)
    //updateQuery(state)
  }
  //console.log(field + option.name + option.group)
  return (
    <MakerOption key={field + option.name + option.tokenAddress} onClick={selectOption}>
      <CurrencyLogo size='32px' currency={option} style={{verticalAlign: 'middle'}} />
      <div style={{display: "inline-block", paddingLeft: "10px"}}>{option.text}</div>
    </MakerOption>
  )
}

const buildMakerOption = (state: any, field: string)=> (option: any)=> {
  return makerOption(state, field, option)
}

const searchFilter = (state: any, field: string)=> (x: any)=> {
  return x.text.toUpperCase().indexOf(state.data[field].search.toUpperCase()) > -1
}

const changeSearch = (state: any, field: string)=> (e: any)=> {
  state.data[field].search = e.target.value;

  //if (state.data[field].options.filter(searchFilter(state, field)).length == 0) {
  //  state.data[field].options = []
  //}
}

const createRef = (obj: any)=> {
  if (obj) {
      obj.focus()
      obj.select()
  }
}

const makerOptions = (state: any, field: string)=> {
  
  const options = state.data[field].options.filter(searchFilter(state, field))


  return (
      <MakerOptions>
        <MakerOption key={field}>
          <input ref={createRef} value={state.data[field].search} onChange={changeSearch(state, field)} placeholder='Search' style={{ background: "transparent", color: "white", border: "0", outline: "none" }} />
        </MakerOption>
        { options.length == 0 ? (<div style={{ color: "#CCC" }}>Empty</div>) : options.map(buildMakerOption(state, field)) }
      </MakerOptions>
  )
}

const arrow = (state: any, field: string)=>  {
  return (
    <>
      <SendDropDownCoinsArrow></SendDropDownCoinsArrow>
      { state.data[field].options.length > 0 ? makerOptions(state, field) : null }
    </>
  )

}

const onMouseEnter = (state: any, field: string)=> ()=> {
  const options = state.data.coins.filter((x:any)=> x.chainId == state.chainId)

  if (options.length == 0)
    return
  //console.log("options", options.length)
  state.data[field].options = options
}

const onMouseLeave = (state: any, field: string)=> ()=> {
  state.data[field].options.length = 0
}

export default function Component(state: any, field: string) {

    if (state.data[field].coin == -1) {
      return null
    }

    const coin = state.data.coins[state.data[field].coin];

    if (coin == null) {
      return (<div>{field} {state.data[field].coin} not found</div>)
    }

    return (
      <div onMouseEnter={onMouseEnter(state, field)} onMouseLeave={onMouseLeave(state, field)} style={{position: "relative"}}>
        <SendDropDownCoins><CurrencyLogo currency={state.data.coins[state.data[field].coin]} />
          <SendDropDownCoinsText>{coin.text}</SendDropDownCoinsText>
          { state.data.coins.length > 1 ? arrow(state, field) : null}
        </SendDropDownCoins>
      </div>
    )
}