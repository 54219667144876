import React from 'react';
import styled, { keyframes }  from 'styled-components'
import { Loading } from './Loading'

const Page = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 1px solid #6b6868;
  color: #6b6868;
  text-align: center;
  line-height: 30px;
  margin: 2px;
  cursor: pointer;
  vertical-align: top;
  &.active {
    color: white;
    cursor: default;
  }
`

// eslint-disable-next-line react/display-name
const createPage = (state: any, refreshFunction: any) => (page: number) => {
  const choosePage = async () => {
    if (state.data.loadingButton != null) 
      return
    state.data.currentPage = page; 
    state.data.loadingButton = "page" + page;
    await refreshFunction(state);
    state.data.loadingButton = null;
    window.scrollTo(0, 0);
  };

  const active = state.data.currentPage === page ? "active" : ""

  return (
    <Page className={active} key={page} onClick={choosePage}>
      { state.data.loadingButton == "page" + page ? (<div style={{display: "inline-block", paddingTop: "3px"}}><Loading /></div>) : page + 1}
    </Page>
  );
};
// eslint-disable-next-line react/display-name
export const Pages = (state: any, refreshFunction: any) => {
  if (state.data.totalPages < 2)
    return;

  const pages = [...Array(state.data.totalPages).keys()];
  
  return (<div style={{textAlign: "center"}}>
    {pages.map(createPage(state, refreshFunction))}
  </div>);

};
