import React from 'react'
import styled from 'styled-components/macro'

import SwitchTab from './SwitchTab.tsx'

const SwitchTabs = styled.div<{ page: string }>`
    width: 224px;
    margin-top: 17px;
    display: inline-block;
    height: 55px;
    position: relative;
    > label {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        background: #1F2736; /*#121823*/
        background: linear-gradient(rgb(53, 58, 64) 0%, rgb(22, 23, 27) 100%);
        border-radius: 30px 30px 30px 30px;
        box-shadow:
            inset 0 3px 8px 1px rgba(0,0,0,0.5),
            inset 0 1px 0 rgba(0,0,0,0.5),
            0 1px 0 rgba(255,255,255,0.2);
            -webkit-transition: all .5s ease;
        transition: all .5s ease;
    }
    > input {
        appearance: none;
        display: none;
    }

    > input ~ label i {
        display: block;
        height: 51px;
        width: 120px;
        position: absolute;
        left: 2px;
        top: 2px;
        z-index: 2;
        border-radius: inherit;
        background: #283446;
        background: rgb(53, 58, 64);
        box-shadow:
            inset 0 1px 0 rgba(255,255,255,0.2),
            0 0 8px rgba(0,0,0,0.3),
            0 12px 12px rgba(0,0,0,0.4);
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
    }

    > label + span {
        content: "";
        display: inline-block;
        position: absolute;
        right: 0px;
        top: 17px;
        width: 18px;
        height: 18px;
        border-radius: 10px;
        background: #283446; 
        background: gradient-gradient(#36455b, #283446);
        box-shadow:
            inset 0 1px 0 rgba(0,0,0,0.2),
            0 1px 0 rgba(255,255,255,0.1),
            0 0 10px rgba(185,231,253,0),
            inset 0 0 8px rgba(0,0,0,0.9),
            inset 0 -2px 5px rgba(0,0,0,0.3),
            inset 0 -5px 5px rgba(0,0,0,0.5);
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        z-index: 2;
    }

    &.SellRequestSuccess {
        color: transparent;
    }

    > input:checked ~ label + span { 
        content: "";
        display: inline-block;
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 10px;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        z-index: 2;
        background: #b9f3fe; 
        background: gradient-gradient(#ffffff, #77a1b9);
        box-shadow:        
            inset 0 1px 0 rgba(0,0,0,0.1),
            0 1px 0 rgba(255,255,255,0.1),
            0 0 10px rgba(100,231,253,1),
            inset 0 0 8px rgba( 61,157,247,0.8),
            inset 0 -2px 5px rgba(185,231,253,0.3),
            inset 0 -3px 8px rgba(185,231,253,0.5);
    }

    > input:checked ~ label i {
        left: auto;
        left: 46%;
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
    }

    i.SellRequestSuccess {
        left: 54px !important; 
    }
`

class Checkbox extends React.Component {
    constructor(props) {
      super(props);
      this.state = { checked: false }
      this.handleCheck = this.handleCheck.bind(this);
    }
  
    handleCheck(e){
     this.setState({
      checked: e.target.checked
     })
   }
  render(){
    return (
        <input
          name="toggle"
          type="checkbox"
          checked={this.state.checked}
          onChange={this.handleCheck}
        />
     );
  }
  }

export default function Component(state: any) {
    
    const maker = state.data.coins[state.data.maker.coin];

    return (
        <SwitchTabs>
            <input type="checkbox" name="toggle"  checked={state.root.page == "Buy"} /> 
            <label htmlFor="toggle"><i className={state.root.page}></i></label>
            { state.root.page == "Buy" || state.root.page == "Sell" ?  (<>
                {SwitchTab(state, "Sell")}
                {SwitchTab(state, "Buy")}
            </>) : null }
        </SwitchTabs>
    )
}