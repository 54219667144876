import React from 'react'
import styled from 'styled-components/macro'
import Lottie from 'react-lottie-player'
import globearth from './verified.json'
//import { colorify } from 'lottie-colorify';
import { ButtonPrimary } from '../../components/Button'
import Navigate from '../Navigate'
import { state } from 'pages/state'


export default function Component(allData: any) {
    return (
        <div style={{textAlign: "center", padding: "30px"}}>
            <div style={{ maxWidth: "400px", display: "inline-block", paddingBottom: "50px" }}>
                <div>
                    <div style={{display: "inline-block"}}>
                        <Lottie play loop={2} animationData={globearth} style={{ width: 150, height: 150 }}></Lottie>
                    </div>
                </div>
                <div style={{ color: "#CCC" }}>{state.data.successText}</div>
                <div style={{ paddingTop: "30px" }}>
                    <ButtonPrimary onClick={seeAllOrdres(allData)}>See All Orders</ButtonPrimary>
                </div>
            </div>
        </div>
    )
}

const seeAllOrdres = (state: any) => ()=> {
    Navigate(state, "Buy");
}
