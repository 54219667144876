import Web3 from 'web3'
import { NETWORK_URLS } from '../../connectors'
//import ERC20_ABI from '../../abis/erc20.json'
import POOL_ABI from '../../abis/pool.json'
//import POOL_ABI from '../../abis/pool.json'
///import { transaction } from 'mobx'
//import resetSwapInfo from '../resetSwapinfo'
//import { makeHumanAmounts } from './makeHumanAmounts'
//import { getBalanceInfo } from './getTokenInfo'
import getMakerAddress from '../getMakerAddress.js';

//import { BigNumber } from "ethers";

const getRange = (length, count)=> {
  return (count < 1 || length < 0) ? [] : [length].concat(getRange(length - 1 , count - 1));  
}

const makeRequest = (state, makerPoolContract)=> (index)=> {
  return makerPoolContract.methods.getActiveVesting(index).call().then((x)=> ({...x, index}));
}

const getItems = async (state, makerPoolContract, length, pageSize)=> {
  if (length == 0)
    return []
  return await Promise.all(getRange(length - 1, pageSize).map(makeRequest(state, makerPoolContract)));
}

const getPage = async (state, page)=> {
  const makerWeb3 = new Web3(NETWORK_URLS[state.data.maker.chainId])
  const makerAddress = getMakerAddress(state);
  const makerPoolContract = new makerWeb3.eth.Contract(POOL_ABI, makerAddress)
  // new contract standard
  const length = await makerPoolContract.methods.getActiveVestingLength().call();
  const pageSize = state.data.pageSize;
  state.data.totalPages = Math.floor(length / pageSize);
  return await getItems(state, makerPoolContract, length - (page * pageSize), pageSize);
}

const onlyMine = (state: any)=> (order: any)=> {
    return order.receiver == state.account;
}

async function refreshPoolInfo(state: any, reset: bool) {
  if (reset) {
    state.data.currentPage = 0;
    state.data.totalPages = 0;
  }
  const vestings = await getPage(state, state.data.currentPage);
  state.data.vestings = vestings.filter(onlyMine(state)).map((vesting: any)=> ({...vesting, toClaim: null}));
}

export default refreshPoolInfo
