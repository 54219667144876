import refreshSwap from './refreshSwap'



export default async function(state: any, page: string, alert) {
    state.root.page = page
    //console.log("navigate")
    //state.data.loading = true
    await refreshSwap(state, alert)
    //state.data.loading = false
}