import Web3 from 'web3';

const getAddress = (address: string)=> {
  if (address = "native") {
    return "0x0000000000000000000000000000000000000000"
  }
  else {
    return address
  }
}

export const createLimitOrder = (state: any, swapContract: any, alert: any) => () => {
  state.data.loadingButton = true;

  const validators = [];

  if (+state.data.maker.amount == 0) {
    validators.push("Maker amount is 0");
  }

  if (+state.data.maker.amount > +state.data.maker.balance) {

    validators.push("Balance is lower than amount");
  }

  if (+state.data.taker.amount == 0 && !state.data.limitBuyer ) {
    validators.push("Taker amount is 0");
  }

  if (state.data.limitBuyer && !Web3.utils.isAddress(state.data.maker.p2pAddress)) {
    validators.push("Please deactivate `Only this address can buy` field or enter the buyer address");
  }

  if (validators.length > 0) {
    state.data.loadingButton = null;
    for (const i in validators) {
      alert.info(validators[i]);
    }
    return;
  }

  
  const address_give = state.data.coins[state.data.maker.coin].tokenAddress;
  const address_get = state.data.coins[state.data.taker.coin].tokenAddress;
  const uint_amountGive = Web3.utils.toWei(state.data.maker.amount.toString(), "ether");
  const uint_amountGet = Web3.utils.toWei(state.data.taker.amount.toString(), "ether");
  const address_buyer = state.data.limitBuyer ? state.data.maker.p2pAddress : "0x0000000000000000000000000000000000000000";
  const value = address_give == "0x0000000000000000000000000000000000000000" ? uint_amountGive : "0";
  console.log("value", value)
  const sec = 86400
  const vestingPeriod = state.data.enableVesting ? state.data.maker.vesting * sec : 0;
  const vestingInterval = state.data.enableVesting ? Math.floor(state.data.maker.vesting * sec / state.data.maker.vestingInterval) : 0;
  //console.log("vesting", vestingPeriod, vestingInterval);


  if (swapContract == null) {
    state.data.loadingButton = null;
    return alert.info("Contract is null");
  }

  //console.log("swapContract", swapContract)
  //console.log("address_give", address_give)
  //console.log("address_get", address_get)
  //console.log("uint_amountGive", uint_amountGive)

  console.log(address_give, address_get, uint_amountGive, uint_amountGet, address_buyer, vestingPeriod, vestingInterval)
  // new contract standard
  swapContract.makeOrder(address_give, address_get, uint_amountGive, uint_amountGet, address_buyer, vestingPeriod, vestingInterval, {
    gasLimit: 480000,
    value: value
  }).then((response: any) => {
    if (response.hash) {
      response
        .wait(1)
        .then((x: any) => {
          state.data.loadingButton = null;
          state.root.page = "SellRequestSuccess"
          state.data.txid = response.hash
          state.data.successText = "Your order has been placed"
          
        })
        .catch((x: any) => {
          state.data.loadingButton = null;
          alert.info("" + x);
        });
    }
  })
    .catch((error: Error) => {
      state.data.loadingButton = null;

      if (error && error.message) {
        alert.info("" + error.message);
      }
      else {
        alert.info("" + error);
      }


    });
};
