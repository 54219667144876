import Web3 from 'web3'
import { NETWORK_URLS } from '../../connectors'
import POOL_ABI from '../../abis/pool.json'
import { transaction } from 'mobx'
import resetSwapInfo from '../resetSwapinfo'
import { makeHumanAmounts } from './makeHumanAmounts'
import { getBalanceInfo } from './getTokenInfo'
//import { BigNumber } from "ethers";
import getMakerAddress from '../getMakerAddress.js';
import changeAmount from '../SwapMain/changeAmount';

async function refreshSwapInfoEvm(state: any) {
  const makerAddress = getMakerAddress(state);
  const data = state.data
  const account = state.account
  const maker = data.coins[data.maker.coin]
  const takerCoinIndex = data.taker.coin;
  const makerWeb3 = new Web3(NETWORK_URLS[maker.chainId])
  const makerPoolContract = new makerWeb3.eth.Contract(POOL_ABI, makerAddress)
  const makerBalanceInfo = await getBalanceInfo(state, maker, makerWeb3, account)
  const allowanceBN = makerBalanceInfo.allowanceBN;
  const makerBalance = makerBalanceInfo.balance;
  const networkFee = await makerPoolContract.methods.fee().call();
  const taker = data.coins[takerCoinIndex];
  const takerWeb3 = new Web3(NETWORK_URLS[taker.chainId])
  const takerBalanceInfo = await getBalanceInfo(state, taker, takerWeb3, account)
  const takerBalance = takerBalanceInfo.balance
  transaction(()=> {
    data.maker.fee = networkFee.toString()
    data.maker.balance = makeHumanAmounts(makerBalance, maker.decimals)
    data.maker.amount = data.maker.balance
    data.maker.placeholder = 0;
    data.maker.allowance = allowanceBN.toString();
    data.taker.coin = takerCoinIndex
    data.taker.placeholder = 0;
    data.taker.balance = makeHumanAmounts(takerBalance, taker.decimals)
    changeAmount(state, "maker")({ target: { value: data.maker.balance } });
  })

}


async function refreshSwapInfo(state: any, reset) {
  
  const data = state.data;
  const account = state.account;
  const chainId = state.chainId;

  data.account = account;

  if (reset)
    resetSwapInfo(state)

  const supportedMakerCoins = data.coins.filter((c)=> c.chainId == chainId)
  
  if (supportedMakerCoins.length == 0)
    return
  

  const makerCoinIndex =  
    data.maker.coin ? data.maker.coin : data.coins.indexOf(supportedMakerCoins[0])

  //const maker = data.coins[makerCoinIndex];
  data.taker.chainId = chainId
  

  if (account == null || chainId == null) {
    return null
  }

  if (data.taker.coin == null) {
    data.maker.coin = makerCoinIndex
    data.taker.coin = data.coins.indexOf(supportedMakerCoins[1])
  }

  return await refreshSwapInfoEvm(state)

}

export default refreshSwapInfo
