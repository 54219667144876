import Web3 from 'web3';
import supportedNetworks from './supportedNetworks.js';
import { normalCase } from './NetworkBox';
import { transaction } from 'mobx';
import { updateQuery } from '../updateQuery'


const checkNetwork = (chainId, cb)=> {

  window.cardano.getNetworkId().then((network)=> {

    if ("cardano:" + network ==  chainId) {
        cb(null)
    }
    else {
      cb(`Please switch to ${chainId == 'cardano:0' ? 'TESTNET' : 'MAINNET' } network in your cardano wallet manually`)
    }

  }).catch((e)=> {
    cb(e)
  })
}

const switchCardano = (state, chainId, cb)=> {
  const data = state.data;
  
  enableCardano(state, (err)=>{

    if (err)
      return cb(err)

    checkNetwork(chainId, (err)=> {

        if (err)
          return cb(err)

        const taker = data.coins[data.maker.coin]
        const family = 
          data.coins.filter((x) => x.group == taker.group)
    
        const maker =
          family.find((x)=> x.chainId == chainId)
        //const taker =
        //  family.find((x)=> x.chainId != chainId)
    
        //window.cardano.getNetworkId()
        transaction(()=> {
    
          //data.taker.coin = data.coins.indexOf(taker)
          console.log("maker", maker)
          data.maker.coin = data.coins.indexOf(maker)
          data.maker.chainId = chainId
          data.taker.chainId = taker.chainId
          updateQuery(state)
          cb("Your Cardano wallet is connected")
  
      })


    })


    
  })

}



const enableCardano = (state, cb)=> {

  if (window.cardano) {
    
    if (state.data.nami.type == null) {
      return cb({message: "Please connect Cardano wallet" })
    }

    window.cardano.isEnabled().then((isEnabled) => {


      if (isEnabled) {
        cb(null)
      }
      else {
          window.cardano.enable().then((x)=> {

              cb(null)


          }).catch((err)=> {
            cb(err)
          })
          
      }


    })

    

  }
  else {
    alert("Please install and configure Nami wallet in your browser");
  }

}

const switchEvmNetwork = (x: any, cb)=> {
  const explorer_config = {
    chainId: Web3.utils.toHex(x.id),
    chainName: normalCase(x.name),
    nativeCurrency: {
      name: x.name,
      symbol: x.coin,
      decimals: 18
    },
    iconUrls: [],
    rpcUrls: x.rpc,
    blockExplorerUrls: [x.blockExplorer]
  };
  

  const isNative = [1, 3].indexOf(x.id) > -1;
  const method = isNative ? "wallet_switchEthereumChain" : 'wallet_addEthereumChain';
  
  const params = isNative ? { chainId: Web3.utils.toHex(x.id) } : explorer_config;

  console.log("add", method, params)
  
  if(window.ethereum) {
    window.ethereum
    .request({
      method: method,
      params: [JSON.parse(JSON.stringify(params))]
    })
    .then(() => {
      cb(null);
    })
    .catch((error: any) => {
      cb(error);
    });
  }
  else {
    cb("Please connect your wallet")
  }


}

export const switchNetwork = (state: any, chainId: number, cb) => {
  
  if (chainId == null)
    return cb("Expected chain id");

  const x = supportedNetworks.find((q: any) => q.id.toString() == chainId.toString() )

  if (x)
    return switchEvmNetwork(x, cb)
  
  if (chainId.toString().indexOf('cardano') > -1)
    return switchCardano(state, chainId, cb)

  return cb("Not Supported Chain")

}
